import React from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import * as actions from "../../../../action";
import common_styles from "../mainPage.module.scss";
import styles from "./headerInput.module.scss";
import "./selectDrop.css";
import headerImg from "../../../../images/crane_header.png";
import headerImgMobile from "../../../../images/crane_header_mobile.png";
import Select from "react-dropdown-select";
import { navigate } from "gatsby";
import _ from "lodash";
import {
  MATERIAL_STEPS_NUMBER,
  TRANSPORT_STEPS_NUMBER,
  COMPANY_INFO_STEPS_NUMBER,
} from "../../../constants";
import { trackRedirection } from "../../../utils/eventTracker";

class HeaderInput extends React.Component {
  style = {
    WebkitBoxSizing: "border-box",
    MozBoxSizing: "border-box",
    boxSizing: "border-box",
    width: "100%",
    height: "76px",
    background: "#FFFFFF",
    paddingLeft: "9px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    textTransform: "capitalize",
    color: "#2E2D45",
  };

  constructor(props) {
    super(props);
    this.state = {
      material: null,
      weight: null,
      error: null,
      weightInputActive: false,
    };

    //get reference for input
    this.nameRef = React.createRef();

    
  }

  static getDerivedStateFromProps(props, state) {
    if (props.loadedFromToken) {
      const numberOfElementsLoaded =
        MATERIAL_STEPS_NUMBER * props.lead.cart.length +
        TRANSPORT_STEPS_NUMBER +
        COMPANY_INFO_STEPS_NUMBER -
        1;
      props.setElementsLoad(numberOfElementsLoaded);
      return { elementsLoaded: numberOfElementsLoaded };
    }
    return null;
  }

  componentDidUpdate() {
    this._setCursorPositions();
  }

  _setCursorPositions = () => {
    //reset the cursor position for input
    this.nameRef.current.selectionStart = this.state.weight
      ? this.state.weight.length
      : 0;
    this.nameRef.current.selectionEnd = this.state.weight
      ? this.state.weight.length
      : 0;
  };

  onChange = value => {
    let material = "";
    if (value.length) {
      material = value[0].materialId;
    }
    this.setState({ material });
  };

  onChangeKilos(event) {
    let value = event.currentTarget.value;
    value = value.replace(/[^\d.-]/g, "");
    if (value === "" || !isNaN(value)) {
      this.setState({ weight: value, error: null });
    } else {
      this.setState({ weight: null, error: "Enter the quantity to proceed" });
    }

    if (value !== "") {
      this.setState({ weightInputActive: true });
    } else {
      this.setState({ weightInputActive: false });
    }
  }

  redirect = () => {
    const { material, weight } = this.state;
    if (material && weight) {
      // Ask BO if we need to redirect the page
      this.props.shouldRedirectToWebshop([
        { productID: material, quantity: parseFloat(weight) },
      ]);
    } else if (!material) {
      this.setState({ error: "Enter the material to proceed" });
    } else if (!weight) {
      this.setState({ error: "Enter the quantity to proceed" });
    }
  };

  redirectToProForm = () => {
    const { material, weight } = this.state;
    let url = "?materialId=" + material + "&weight=" + weight + "&mu=t";
    navigate("/anfrage" + url);
  };

  redirectToWebshop = () => {
    const { material, weight } = this.state;
    const quantity = weight * 1000; // Set to tonnes
    let url = `https://www.schrott24.de/co/cart/${material}?&quantity=${quantity}`;
    if (this.props.isSwissSite) {
      //Redirect to sorec and also add the event in analytic
      url = `https://shop.sorec.ch/co/cart/g/u5YiZt7yJRH4teEmZ/${material}?quantity=${quantity}`;
      trackRedirection(productId, this.props.products, quantity);
    }
    window.location = url;
  };

  getWeightLabel = () => {
    const { t } = this.props;
    if (this.state.weight) {
      return (
        t("You entered") +
        " " +
        this.getWeightFormatted() +
        " " +
        t("Kilograms")
      );
    }
  };

  getWeightFormatted = () => {
    const value = this.state.weight * 1000;
    // leave undefined to use the browser's locale, or use a string like 'en-US' to override it.
    return value.toLocaleString(undefined);
  };

  keyUp = () => {};

  render() {
    const { t } = this.props;
    const { weight } = this.state;
    let products = _.cloneDeep(this.props.products);

    // This code it's because Select component uses value property of products as a key
    // And there are products repeated because they could have several parents -> repeated values in the collection
    // Because of that the render method causes an error and the dropdown it's not working properly
    if (products) {
      let count = 0;
      products.map(p => {
        p.label = t(p.label);
        p.value = count++;
      });
    }

    // Check if the page should be redirected
    if (this.props.redirectToWebshopCheked) {
      if (this.props.redirectToWebshop) {
        this.redirectToWebshop();
      } else {
        this.redirectToProForm();
      }
    }

    return (
      <div className={styles.header}>
        <div className={styles.inputWrapper}>
          <div className={styles.textWrapper}>
            <h1 className={styles.titleWrapper}>
              <span
                className={[common_styles.title, common_styles.whiteText].join(
                  " "
                )}
              >
                {t("Schrott24 bietet")}{" "}
                {t(
                  "Ihnen maßgeschneiderte Lösungen, um den Wert Ihres recycelten Altmetalls zu erhöhen"
                )}
              </span>
            </h1>
          </div>

          <div className={styles.formContainer}>
            <div className={styles.headerMobileImg}>
              <img src={headerImgMobile} alt="crane" />
            </div>
            <span className={styles.formTitle}>
              {t("Was wollen Sie verkaufen?")}
            </span>
            <div className={styles.inputContainer}>
              <div className={styles.materialSelectContainer}>
                <Select 
                  style={this.style}
                  placeholder={"  "}
                  searchable={true}
                  clearable={this.state.material ? true : false}
                  options={products}
                  onChange={value => this.onChange(value)}
                  className={styles.selectDropdown + " data-hj-whitelist"}
                  searchBy={"label"}
                />
                <label className={this.state.material ? styles.active : styles.labelMaterial}
               >
                  {t("Material") + "  "}
                </label>
              </div>

              <div className={styles.inputGroup}>
                <input
                  ref={this.nameRef}
                  className={styles.amountInput + " data-hj-whitelist"}
                  onChange={value => {
                    this.onChangeKilos(value);
                  }}
                  value={this.state.weight && this.state.weight + " t"}
                />
                <label
                  className={[
                    styles.amountInputLabel,
                    this.state.weightInputActive ? styles.active : "",
                  ].join(" ")}
                  
                >
                  {t("Enter quantity (t)")}
                </label>

                {this.state.error ? (
                  <span className={styles.errorExplanation}>
                    {t(this.state.error)}
                  </span>
                ) : (
                  <span className={styles.explanation}>
                    {this.getWeightLabel()}
                  </span>
                )}
              </div>
            </div>
            <button className={styles.submitButton} onClick={this.redirect}>
              {t("WEITER")}
            </button>
          </div>
          <div className={styles.diagonalBackgroundSquare}></div>
          <div className={styles.diagonalBackgroundLine}></div>
        </div>

        <div className={styles.imageWrapper}>
          <img src={headerImg} alt="crane" />
        </div>
      </div>
    );
  }
}

function mapStateToProps({ leadForm }) {
  return {
    products: leadForm.initialInfo.products,
    redirectToWebshop: leadForm.redirectToWebshop,
    redirectToWebshopCheked: leadForm.redirectToWebshopCheked,
  };
}

export default connect(mapStateToProps, actions)(withNamespaces()(HeaderInput));
