import React, { useState, useContext, useEffect } from "react";
import NavMenu from "../../components/nav-menu/NavMenu";
import Helmet from "react-helmet";
import HeaderInputSection from "../../components/main-page/sections/headerInput/HeaderInput";
import CompleteSolutionSection from "../../components/main-page/sections/completeSolution/CompleteSolution";
import Truck from "../../components/main-page/sections/truck/Truck";
import Cards from "../../components/main-page/sections/cards/Cards";
import MapSection from "../../components/main-page/sections/map/Map";

import SEOComponent from "../../components/Shared/Seo";

import GroupLegal from "../../components/group-legal/GroupLegal";
import IncreaseRevenueSection from "../../components/main-page/sections/increaseRevenue/IncreaseRevenue";

import EuropeanMapSection from "../../components/main-page/sections/map/EuropeanMap";
import EfficienceSection from "../../components/main-page/sections/efficience/Efficience";
import OnlineDocumentsSection from "../../components/main-page/sections/onlineDocuments/OnlineDocuments";
import ConsultingSection from "../../components/main-page/sections/consulting/Consulting";
import { Link } from "gatsby";
import RequestFreeConsultationSection from "../../components/main-page/sections/requestFreeConsultation/RequestFreeConsultation";
import Footer from "../../components/main-page/sections/footer/Footer";
import styles from "./index.module.scss";
import * as actions from "../../action";
import { connect } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18n from "../../i18n/i18n";
import DomainContext from "../../context/DomainContext";
import { propTypes } from "react-spinkit";
import MarketInsights from "../../components/main-page/sections/marketInsights/MarketInsights";

import IncreaseRevenue from "../../components/main-page/sections/increaseRevenue/IncreaseRevenue";

import MarketUpdatePopUp from "../../components/main-page/popUp/marketUpdatePopUp";

import MetaloopBanner from "../../components/metaloop-banner/metaloopBanner";
const IndexPage = props => {
  const domain = useContext(DomainContext);

  const sitesShowEuropeanMap = ["ch", "en"];

  //let url = window.location.origin;

  let europeanMap = false;
  const isSwissSite = "ch" === domain;

  europeanMap = sitesShowEuropeanMap.filter(s => s.includes(domain)).length > 0;

  const [homeUrl, setHomeUrl] = useState("");
  const [showEuropeanMap, setShowEuropeanMap] = useState(europeanMap);

  useEffect(() => {
    props.loadInitialInfo();
  });

  return (
    <I18nextProvider i18n={i18n} defaultNS={"default"}>
      <SEOComponent page="pro" />
      <NavMenu />

      <div className={styles.breadcrumb}>
        <a href={homeUrl}>Home</a>
        <span className={styles.separator}>/</span>
        <span> Pro</span>
      </div>
      <MetaloopBanner />
      <HeaderInputSection isSwissSite={isSwissSite} />

      <CompleteSolutionSection />

      {showEuropeanMap ? (
        <EuropeanMapSection isSwissSite={isSwissSite} />
      ) : (
        <MapSection />
      )}

      <Cards />

      <Truck />

      <EfficienceSection />

      <OnlineDocumentsSection />

      <ConsultingSection />
      <RequestFreeConsultationSection />
      <Footer />
      <GroupLegal />
      {domain === "en" ? null : <MarketUpdatePopUp />}
    </I18nextProvider>
  );
};

function mapStateToProps({ leadForm }) {
  return {};
}

export default connect(mapStateToProps, actions)(IndexPage);
