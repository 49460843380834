import React from "react";
import { withNamespaces } from "react-i18next";
import styles from "./europeanMap.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import SwissMap from "../../../../images/map-swiss.svg";

class EuropeanMap extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div className={styles.europeanMapBanner}>
        <div className={styles.europeanMapBannerBackgrounds}>
          <div className={styles.europeanMapBannerBackgroundsBlue}></div>
          <div className={styles.europeanMapBannerBackgroundsWhite}></div>
          <div className={styles.europeanMapBannerBackgroundFullBlue}></div>
        </div>

        <div className={styles.europeanMapTextContainer}>
          <div className={styles.textMapContainer}>
            <span>{t("Zusatzservice")}</span>
            <h2>{t("So Profitieren Sie als Industrie-Kunde")}</h2>
            <p>
              {t(
                "Durch unser globales Netzwerk aus Recyclingspezialisten, Aufbereitungsanlagen und Transportunternehmen, findet Ihr Material den direktesten Weg zurück in den Materialkreislauf."
              )}
            </p>

            <p>
              {t(
                "So profitieren Sie nicht nur von sehr guten Preisen, sondern können auch auf eine reibungslose Abwicklung aus einer Hand zählen."
              )}
            </p>

            <a href="/anfrage">{t("Verkaufen Sie an uns")}</a>
          </div>
        </div>

        <div className={styles.europeanMapBannerContainer}>
          {this.props.isSwissSite ? (
            <img src={SwissMap} className={styles.mapImg} alt="Swiss map" />
          ) : (
            <StaticImage
              imgClassName={styles.mapImg}
              src={"../../../../images/map.svg"}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withNamespaces()(EuropeanMap);
